import React, { FC } from "react";
import { PageProps } from "gatsby";
import Layout from "../components/layout/layout";
import SEO from "../components/seo";

import "./404.css";

const NotFoundPage: FC<PageProps<void>> = () => (
  <Layout path="/404">
    <SEO title="Nout Found" />
    <div className="center-container">
      <section className="center padding">
        <h1 className="error-code">404</h1>
        <h2 className="error-message">Page Not Found</h2>
      </section>
    </div>
  </Layout>
);

export default NotFoundPage;
